import request from "tools/request.js"

class get_orders_num {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;
		 
		let session = localStorage.getItem("session");

		let params = { 
			session: session, 
		}

		request.post('/system/tabs/get_orders_num', params).then(res => { 
			 that.parent.parent.tabs["orders"][0]["no"] = res.data.weiwancheng_num;
			 that.parent.parent.tabs["orders"][1]["no"] = res.data.qianzhi_num;
			 that.parent.parent.tabs["orders"][2]["no"] = res.data.baojia_num;
			 that.parent.parent.tabs["orders"][3]["no"] = res.data.guanwangcaigou_num;
			 that.parent.parent.tabs["orders"][4]["no"] = res.data.tuihuo_num;
			 that.parent.parent.tabs["orders"][5]["no"] = res.data.quanbu_num;
			 that.parent.parent.tabs["orders"][6]["no"] = res.data.zixun_num;
		})
 
	}
}

export default get_orders_num
