<template>
	<el-row class="headbar" :gutter="40">
		<el-col :span="4">
			<div class="grid-content bg-purple logo">
				<i class="el-icon-s-goods logo-icon"></i>
				<div class="logo-a-div">
					<a class="logo-a-div-a1">星空羽球 管理后台</a><br>
					<a class="logo-a-div-a2">Xingkong Badminton Admin</a>
				</div>

			</div>
		</el-col>
		<el-col :span="16" style="    height: 20px;">
			&nbsp;
		</el-col>
		<el-col :span="4">
		<!-- 	<el-col :span="5">
				<el-badge :value="3" class="item">
					<div @click="drawer = true">
						<el-avatar shape="square" :size="40" fit="fit" :src="user_headimg">
						</el-avatar>
					</div>
				</el-badge>

			</el-col> -->
		<!-- 	<el-col :span="18">
				<div class="yu_content_left">
					<div>{{user_nickname}}:在线 </div>
					<el-popover placement="bottom" width="120" trigger="click">
						<el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">

							<el-menu-item index="1">
								<i class="el-icon-menu"></i>
								<span slot="title">离线</span>
							</el-menu-item>
							<el-menu-item index="2">
								<i class="el-icon-document"></i>
								<span slot="title">设置信息</span>
							</el-menu-item>
							<el-menu-item index="3" @click="click_exit()">
								<i class="el-icon-setting"></i>
								<span slot="title">退出</span>
							</el-menu-item>
						</el-menu>
						<el-button class="yu_pop yu_head_menu" slot="reference">菜单</el-button>
					</el-popover>

				</div>
			</el-col> -->
		</el-col>
		<el-drawer title="聊天信息" :visible.sync="drawer" size="918px">
			 
		</el-drawer>
	</el-row>

</template>

<script>
 

	export default {
		components: { 
		},
		data() {
			return {
				drawer: false,
				user_headimg: "",
				user_id: "",
				user_nickname: "",
			}
		},
		created() {
			const that = this;

			// that.user_headimg = "http://47.105.75.188:4002/" + localStorage.getItem("user_headimg");
			// that.user_id = localStorage.getItem("user_id");
			// that.user_nickname = localStorage.getItem("user_nickname");
		},
		methods: {



			click_exit() {
				const that = this;

				localStorage.removeItem("quanxian")
				localStorage.removeItem("user_headimg")
				localStorage.removeItem("user_id")
				localStorage.removeItem("user_nickname")
				localStorage.removeItem("user_state")


				setTimeout(function() {
					that.$router.push({
						path: '/login',
						query: {}
					});
				}, 3000);
			}
		}
	}
</script>

<style>
	.headbar {
		height: 100%;
		border-bottom-color: #e6e6e6;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #130c5812;
		padding-top: 20px;
	}

	.logo {
		height: 100%;
		width: 220px;
		margin-top: 1px;
		color: #110A57;
		border-radius: 4px;
		text-align: left;
		font-size: 16px;
	}

	.logo-icon {
		float: left;
		font-size: 38px;
	}

	.logo-a-div {
		float: left;
		margin-left: 9px;
		font-weight: 700;
	}

	.logo-a-div-a1 {
		height: 3px;
		float: left;
	}

	.logo-a-div-a2 {
		font-size: 13px;
		margin-top: -2px;
		float: left;
	}
</style>
