 <template>
	
	<div> 
		<div class="home">
			<el-backtop   :bottom="100">
			    
			 </el-backtop>
			<el-container>
				<el-header height="80px">
					<headbar></headbar>
				</el-header>
				<el-container>
					<el-aside width="200px">
						<sidebar></sidebar>
					</el-aside>
					<el-main>
						<tabs></tabs>
						<slot></slot>
					</el-main>
				</el-container>
			</el-container>
		</div>
	</div> 
	
</template>
<script>
	import sidebar from '../components/sidebar.vue'
	import headbar from '../components/headbar.vue'
	import tabs    from '../components/tabs.vue'
	
	import authority    from 'tools/authority'
	 
	export default {
		components: {
			sidebar,
			headbar,
			tabs 
		},
		created() {
			const that = this;
			
			let auth = new authority(that);
			auth.m_main();
		},
	}
</script>

<style>
	body {
		margin: 0px;
	}

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		font-size: 15px;  
	}
	
</style>
