<template>
	<el-row class="tac">
		<el-col :span="24">
			<el-menu :default-active="active_index" class="el-menu-vertical-demo" collapse-transition="false"
				:collapseTransition="coll_state">
 
				<router-link to="/changdi">
					<el-menu-item index="1"> 
						<i class="el-icon-menu"></i>
						<span slot="title">场地管理</span>
					</el-menu-item> 
				</router-link>
				
				<router-link to="/huodong">
					<el-menu-item index="2"> 
						<i class="el-icon-menu"></i>
						<span slot="title">活动管理</span>
					</el-menu-item> 
				</router-link>
				
				<router-link to="/dingdan">
					<el-menu-item index="3"> 
						<i class="el-icon-menu"></i>
						<span slot="title">订单管理</span>
					</el-menu-item> 
				</router-link>
				 
				 <router-link to="/huiyuan">
				 	<el-menu-item index="4"> 
				 		<i class="el-icon-menu"></i>
				 		<span slot="title">会员管理</span>
				 	</el-menu-item> 
				 </router-link>
				 
				 
				<!-- <router-link to="/xinxi">
					<el-menu-item index="5"> 
						<i class="el-icon-menu"></i>
						<span slot="title">信息管理</span>
					</el-menu-item> 
				</router-link> -->
				
			 
		 
			 
				 
			</el-menu>
		</el-col>

	</el-row>
</template>

<script>
	 

	export default {
		data() {
			return {
				"quanxian": [],
				"active_index": "1",
				"sidebars": { 
					"/changdi": "1",
					"/huodong": "2",
					"/huodongdingdan": "2",
					"/dingdan": "3",
					"/huiyuan": "4",
					"/chongzhijilu": "4", 
					"/xinxi": "5" 
				},
				"coll_state": false
			}
		},
		created() {
			const that = this;
			this.active_index = this.sidebars[this.$route.path]



			// let quanxian2 = JSON.parse(localStorage.getItem('quanxian2'));
			// that.quanxian =  quanxian2
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style>
	.el-submenu__title {
		text-align: left;
	}

	.el-menu-item {
		text-align: left;
	}

	.el-icon-s-marketing {
		margin-top: -4px;
	}
</style>
