<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">订单id:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="ding_id" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>
				<el-col :span="16">
					 
				</el-col>
			</el-row>
			<br />


			<br />
			<el-table :data="m_dingdan_list" style="width: 100%">
				<el-table-column prop="ding_id" label="id">
				</el-table-column>
				<el-table-column prop="ding_shan_id" label="商家id">
				</el-table-column> 
				<el-table-column prop="ding_user_id" label="用户id">
				</el-table-column>  
				<el-table-column prop="shpi_name" label="商品名">
				</el-table-column>
				<el-table-column prop="ding_jine" label="订单金额(包含余额)">
				</el-table-column>
				<el-table-column prop="ding_yue" label="使用的余额">
				</el-table-column>
				<el-table-column prop="ding_time" label="下单时间">
				</el-table-column> 
				 
				<!-- <el-table-column  label="其他" width="200px">
					<template v-if="scope.row.ding_dtype == 1"  slot-scope="scope">
					 
							酒店订单 <br/>
							入住日期 {{scope.row.ding_ruzhu_riqi}} <br/>
							入住天数 {{scope.row.ding_ruzhu_tianshu}} <br/>
							入住人 {{scope.row.ding_ruzhu_ren}}<br/>
							入住手机号 {{scope.row.ding_ruzhu_shoujihao}} 
						 
					</template>
				</el-table-column> -->
				<el-table-column prop="ding_state" label="状态">
				</el-table-column>
				<el-table-column label="操作" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button size="mini" type="danger" @click="f_dingdan_set(scope.mang_id )">发货</el-button>
						 
					</template>
				</el-table-column>
			</el-table>

			<br />
			<br />

			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		 

		<el-drawer title="订单详情" :visible.sync="drawer_2" size="600px">
			<dingdan_info :key="m_yiyu_id" :m_yiyu_id="m_yiyu_id" :parent="me"></dingdan_info>
		</el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

 
	import dingdan_info from './components/dingdan_info/dingdan_info'
	import dingdan_get from "./expand/azdingdan_get.js"


	export default {
		components: {
			layout,
			dingdan_info
		},
		data() {
			return {
				me: this,
				ding_id: "",
				m_dingdan_list: "",
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_yiyu_id: 0
			}
		},
		created() {
			const that = this;

			that.dingdan_get = new dingdan_get(that);
			that.dingdan_get.m_main();

		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.dingdan_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;

				that.dingdan_get.m_main();
			},
			f_dingdan_set(shpi_id) {
				const that = this;

				that.m_yiyu_id = shpi_id;
				that.drawer_2 = true;
			}
		}
	}
</script>
