import request from "tools/request.js"
import get_orders_num from "./expand/get_orders_num.js"
import get_offer_num  from "./expand/get_offer_num.js"

//获取 该频道标题 提示数量
class num_get {
	constructor(parent) {
		const that = this;
		
		this.parent = parent
		
		that.get_orders_num = new get_orders_num(that);
		that.get_offer_num 	= new get_offer_num(that);
	}

	m_main(tabs_name) {
		const that = this;
		 
		 // 销售
		if(tabs_name == "orders"){
			that.get_orders_num.m_main();
		}
		
		// 专柜 
		if(tabs_name == "offer"){
			that.get_offer_num.m_main();
		}
	}
}

export default num_get
