<template>
	<div>

		<el-tabs v-model="editableTabsValue" type="card" >

			<el-tab-pane v-for="(item, index) in editableTabs" @click="m_click(item.name2)" :key="item.name" :label="item.title" :name="item.name"   >
				<span slot="label" @click="m_click(item.name2)">
					<!-- <el-tooltip class="item" effect="dark" content="提示 " placement="bottom-start"> -->
						<span class="span-box"  >
							<span>{{item.title}}</span> 
						</span>
					<!-- </el-tooltip> -->
				</span> 
			</el-tab-pane>


		</el-tabs>
	</div>
</template>

<script>
	import router from 'tools/router.js'
	import authority from 'tools/authority' 
	import num_get from './tabs/num_get.js'
	 
	export default {
		data() {
			return {
				editableTabs: "",
				editableTabsValue: '1',  
				quanxian:[],
				tabs: {
					"announcement": [{
						title: '公告',
						name: "1",
						name2: '/',
						no: '0',
						content: '',
						authority:"99999999"
					} ],
					"changdi": [{
						title: '场地管理',
						name: "1",
						name2: '/changdi',
						no: '0',
						content: '', 
					} ],
					"huodong": [{
						title: '活动管理',
						name: "1",
						name2: '/huodong',
						content: '', 
					},{
						title: '活动订单',
						name: "2",
						name2: '/huodongdingdan',
						content: '', 
					}],    
					"dingdan": [{
						title: '订单管理',
						name: "1",
						name2: '/dingdan',
						content: '', 
					}],  
					"huiyuan": [{
						title: '会员管理',
						name: "1",
						name2: '/huiyuan',
						content: '', 
					},{
						title: '充值记录',
						name: "2",
						name2: '/chongzhijilu',
						content: '', 
					}],   
					"xinxi": [{
						title: '信息管理',
						name: "1",
						name2: '/xinxi',
						content: '', 
					}] 
					 
				},
				tabIndex: 2
			}
		},
		created() {
			const that = this;
			for (let item in this.tabs) {
				for (let item2 in this.tabs[item]) { 
					if (this.$route.path == this.tabs[item][item2]["name2"]) { 
						 
						that.editableTabs = this.tabs[item] 
						that.editableTabsValue = parseInt(item2) + 1 + "";
						
						//获取标题后 数量
						that.num_get = new num_get(that);
						that.num_get.m_main(item);
					};
				}
			}
			 
			
		},
		methods: { 
			m_click(oo){
				const that = this;
				router.push({
					path: oo
				})
			}
		}
	}
</script>
