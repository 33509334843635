import request from "tools/request.js"

// 获取专柜 计数
class get_offer_num {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;
		 
		let session = localStorage.getItem("session");

		let params = { 
			session: session, 
		}

		request.post('/system/tabs/get_offer_num', params).then(res => { 
			 that.parent.parent.tabs["offer"][0]["no"] = res.data.baojia_num;
			 that.parent.parent.tabs["offer"][1]["no"] = res.data.daifahuo_num;
			 that.parent.parent.tabs["offer"][2]["no"] = res.data.fahuo_num;
			 that.parent.parent.tabs["offer"][3]["no"] = res.data.yifahuo_num;
			 that.parent.parent.tabs["offer"][4]["no"] = res.data.yiwancheng_num;
			 that.parent.parent.tabs["offer"][5]["no"] = res.data.tuihui_num;
			 that.parent.parent.tabs["offer"][6]["no"] = res.data.hulue_num;
			 that.parent.parent.tabs["offer"][8]["no"] = res.data.zixun_num; 
		})
 
	}
}

export default get_offer_num
