<template>
	<div class="yu_box_1">
		<div>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					订单id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="ding_id"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					商家id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="ding_shan_id"></el-input>
				</el-col>
			</el-row>
			<br />


		</div>
	</div>
</template>

<script>
	import yiyuanchou_set_get from "./expand/yiyuanchou_set_get.js";
	import yiyuanchou_set_post from "./expand/yiyuanchou_set_post.js";

	export default {

		props: ["parent"],
		data() {
			return {
				m_mang_name: "",
				m_mang_shan_id: "",
				m_mang_shoujia: '',
				m_mang_type: 1,
				m_mang_type_list: [{
					value: 1,
					label: '酒店盲盒'
				}, {
					value: 2,
					label: '餐饮盲盒'
				}, {
					value: 3,
					label: '零食盲盒'
				}],
				m_mang_state_list: [{
					value: 1,
					label: '上架'
				}, {
					value: 2,
					label: '下架'
				}],
			}
		},
		created() {
			const that = this;
			that.manghe_set_get = new manghe_set_get(that);
			that.manghe_set_get.m_main();
		},
		methods: {
			manghe_set() {
				const that = this;
				that.manghe_set_post = new manghe_set_post(that);
				that.manghe_set_post.m_main();
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
